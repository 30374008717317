<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" :width="diaLogSize" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<!-- taskDetail-任务详情 -->
		<div v-if="diaLogType == 'taskDetail'" class="handle_form">
			<el-form ref="taskDetail" :rules="taskRules" :model="taskDetail" label-width="">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item prop="" label="任务标题:">
							<span class="handle_form_value">专注考试1</span>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item label="任务内容:">
							<span class="handle_form_value">内容详情</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="预计完成时间:">
							<span class="handle_form_value">2022-02-12 至 2022-03-22</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="任务状态:">
							<span class="handle_form_value">已完成</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="学生附件:">
							<div class="down_load_box">
								<div class="down_load_btn">
									<img src="@/assets/images/dashboard/downLoad.png">
									<span>附件下载</span>
								</div>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="学生备注:">
							<span class="handle_form_value">2022—1—3日由于某某原因未完成</span>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="submit_btn">
				<span @click="closeDialog" class="submit_btn_cancel">取消</span>
				<span v-preventReClick class="submit_btn_confirm">确定</span>
			</div>
		</div>
		<!-- waitDetail-待办详情 -->
		<div v-if="diaLogType == 'waitDetail'" class="handle_form">
			<el-form ref="waitDetail" :rules="waitRules" :model="waitDetail" label-width="66px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="待办标题:">
							<span class="handle_form_value">{{waitDetail.title}}</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="待办时间:">
							<span class="handle_form_value">{{waitDetail.backlogDate}}</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row" v-if="waitDetail.remark">
					<div class="handle_form_item">
						<el-form-item label="备注:">
							<span class="handle_form_value">{{waitDetail.remark}}</span>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row" v-if="waitDetail.attPath">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="附件:">
							<uploadFile :showReUpload="false" :fileNameP="waitDetail.attNamq?waitDetail.attNamq:''" :fileSrc="waitDetail.attPath" />
							<!-- <div class="down_load_box" @click="uploadDown(waitDetail.attPath)">
								<div class="down_load_btn">
									<img src="@/assets/images/dashboard/downLoad.png">
									<span>附件下载</span>
								</div>
							</div> -->
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label="待办来源:">
							<span
								class="form_tag_green">{{waitDetail.source==1?"自行添加":waitDetail.source==2?"派送任务":"系统派送"}}</span>
						</el-form-item>
					</div>
					<!-- <div class="handle_form_item" @click="getHistoryTask(waitDetail.backlogTaskId)">
						<span class="handle_form_history">查看历史></span>
					</div> -->
				</div>
			</el-form>
		</div>
		<!-- waitEdit-待办添加/编辑 -->
		<div v-if="diaLogType == 'waitEdit'" class="handle_form">
			<el-form ref="waitEdit" :rules="editRules" :model="editDetail" label-width="76px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" prop="title" label="待办标题:">
							<input placeholder="请输入待办标题" class="handle_form_ipt" v-model="editDetail.title"></input>
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'edit'" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="待办时间:">
							<input :disabled="true" placeholder="暂无待办时间" class="handle_form_ipt"
								v-model="editDetail.backlogDate"></input>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="备注:">
							<textarea placeholder="请输入备注" class="handle_form_textarea"
								v-model="editDetail.remark"></textarea>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="附件:">
							<div class="down_load_box">
								<uploadFile :fileNameP="editDetail.attNamq?editDetail.attNamq:''" @successUpload="successUploadMy" :fileSrc="editDetail.attPath" />
							</div>
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'edit'" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="待办来源:">
							<span
								class="form_tag_green">{{editDetail.source == 1?'自行添加':editDetail.source == 2?'派送任务':editDetail.source == 3?'系统派送':''}}</span>
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'edit'" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="待办状态:">
							<radioGroup :currentRadio="editDetail.status+''" @checkRadio="statusRadio"
								:radioList="statusList" />
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'add' || handleType == 'edit'&&editDetail.status == 1" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item :class="'flex_item'" label="派送待办:">
							<!-- <radioGroup v-if="handleType == 'add'" @checkRadio="checkRadio" :radioList="deliveryList" /> -->
							<el-select v-model="editDetail.backlogTaskAppointRequest.refId" class="handle_form_select"
								clearable placeholder="请选择">
								<el-option v-for="item in teacherList" :key="item.id"
									:label="item.realname" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'edit'&&editDetail.status == 1" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="待办时间:">
							<div class="handle_form_date">
								<span class="table_date"><i
										class="form_date_icon el-icon-date"></i>{{editDetail.backlogTaskAppointRequest.backlogDate?editDetail.backlogTaskAppointRequest.backlogDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect"
									v-model="editDetail.backlogTaskAppointRequest.backlogDate" type="date"
									placeholder="选择日期">
								</el-date-picker>
							</div>
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'add' || handleType == 'edit'&&editDetail.status == 1" class="handle_form_row">
					<!-- v-if="editDetail.backlogTaskAppointRequest" -->
					<div class="handle_form_item">
						<el-form-item class="" label="附件:">
							<uploadFile @successUpload="successUploadOther" :fileNameP="editDetail.backlogTaskAppointRequest.attNamq?editDetail.backlogTaskAppointRequest.attNamq:''"
								:fileSrc="editDetail.backlogTaskAppointRequest.attPath" />
						</el-form-item>
					</div>
				</div>
				<div v-if="handleType == 'add' || handleType == 'edit'&&editDetail.status == 1" class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="备注:">
							<textarea placeholder="请输入备注" class="handle_form_textarea"
								v-model="editDetail.backlogTaskAppointRequest.remark"></textarea>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="submit_btn">
				<div @click="getHistoryTask(editDetail.backlogTaskId)" v-if="handleType == 'edit'" class="submit_btn_delete">
					<span>待办记录></span>
				</div>
				<!-- <div v-if="handleType == 'edit'" class="handle_form_item" @click="getHistoryTask(waitDetail.backlogTaskId)">
					<span class="handle_form_history">待办记录></span>
				</div> -->
				<span @click="closeDialog" class="submit_btn_cancel">取消</span>
				<span v-preventReClick @click="submitFn(handleType)" class="submit_btn_confirm">确定</span>
			</div>
		</div>
		<!-- historyDetail-历史代办 -->
		<div v-if="diaLogType == 'historyDetail'" class="handle_form">
			<el-form ref="historyDetail" :rules="historyRules" :model="historyDetail" label-width="66px">
				<div v-for="(item,index) in historyDetail" :key="index">
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item label="待办标题:">
								<span class="handle_form_value">{{item.title}}</span>
							</el-form-item>
						</div>
					</div>
					<!-- <div class="bottom_line"></div> -->
					<div class="">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item label="待办时间:">
									<span class="handle_form_value">{{item.backlogDate}}</span>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item label="备注:">
									<span class="handle_form_value">{{item.remark}}</span>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item label="附件:" class="flex_item">
									<div class="down_load_box" @click="uploadDown(item.attPath)">
										<div class="down_load_btn">
											<img src="@/assets/images/dashboard/downLoad.png">
											<span>点击下载</span>
										</div>
									</div>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item label="代办来源:">
									<span
										class="form_tag_green">{{item.source==1?"自行添加":item.source==2?"派送任务":"系统派送"}}</span>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item label="操作人:">
									<span class="handle_form_value">{{item.createUserName}}</span>
								</el-form-item>
							</div>
							<div class="handle_form_item">
								<el-form-item label="操作时间:">
									<span class="handle_form_value">{{item.updateTime}}</span>
								</el-form-item>
							</div>
						</div>
						<div class="bottom_line"></div>
					</div>
				</div>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
import radioGroup from "@/components/radioGroup/index.vue";
import uploadFile from "@/components/uploadFile/index.vue";
import commonFn from "@/utils/common.js";
import { addTask, detailTask, editTask, historyTask } from "@/api/dashboards";
import { listTeacher } from "@/api/common";
export default {
  components: {
    radioGroup,
    uploadFile
  },
  props: {
    isOpen: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    diaLogSize: {
      type: String,
      default: function() {
        return "579px";
      }
    },
    diaLogTitle: {
      type: String,
      default: function() {
        return "";
      }
    },
    diaLogType: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  data() {
    return {
      handleType: "",
      diaLogVisible: false,
      diaLogDate: "",
      taskRules: {
        title: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change"
          }
        ]
      },
      historyRules: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入待办标题",
            trigger: "change"
          }
        ]
      },
      waitRules: {},
      waitDetail: {},
      taskDetail: {
        title: ""
      },
      historyDetail: [],
      editDetail: {
        attPath: "",
        attNamq: "",
        backlogTaskAppointRequest: {
          refId: "",
          backlogDate: "",
          attPath: "",
          attNamq: "",
          remark: ""
        }
      },
      deliveryList: [
        {
          label: "T",
          value: "老师",
          check: false
        },
        {
          label: "S",
          value: "学生",
          check: false
        }
      ],
      statusList: [
        {
          label: "1",
          value: "未完成"
        },
        {
          label: "2",
          value: "已完成"
        }
      ],
      teacherList: []
    };
  },
  watch: {
    isOpen: {
      handler(val) {
        console.log("isOpen", val);
        this.diaLogVisible = val;
        if (!val) {
          this.editDetail = {
            attPath: "",
            attNamq: "",
            backlogTaskAppointRequest: {
              refId: "",
              backlogDate: "",
              attPath: "",
              attNamq: "",
              remark: ""
            }
          };
        }
      },
      deep: true
    }
  },
  methods: {
    // 获取老师列表
    texcherList() {
      let params = {
        pageIndex: 1,
        pageSize: 99999
      };
      listTeacher(params).then(res => {
        this.teacherList = res.data;
      });
    },
    getCharFromUtf8(str) {
      var cstr = "";
      var nOffset = 0;
      if (str == "") return "";
      str = str.toLowerCase();
      nOffset = str.indexOf("%e");
      if (nOffset == -1) return str;
      while (nOffset != -1) {
        cstr += str.substr(0, nOffset);
        str = str.substr(nOffset, str.length - nOffset);
        if (str == "" || str.length < 9) return cstr;
        cstr += this.utf8ToChar(str.substr(0, 9));
        str = str.substr(9, str.length - 9);
        nOffset = str.indexOf("%e");
      }
      return cstr + str;
    },
    utf8ToChar(str) {
      var iCode, iCode1, iCode2;
      iCode = parseInt("0x" + str.substr(1, 2));
      iCode1 = parseInt("0x" + str.substr(4, 2));
      iCode2 = parseInt("0x" + str.substr(7, 2));
      return String.fromCharCode(
        ((iCode & 0x0f) << 12) | ((iCode1 & 0x3f) << 6) | (iCode2 & 0x3f)
      );
    },
    // 获取待办详情
    getTaskDetail(id) {
      let params = {
        backlogTaskId: id
      };
      this.editDetail = {};
      detailTask(params).then(res => {
        if (res.code == 0) {
          if (!res.data.attNamq && res.data.attPath) {
            let lineIndex = res.data.attPath.lastIndexOf("/");
            let fileName = res.data.attPath.substring(
              lineIndex + 1,
              res.data.attPath.length
            );
            fileName = this.getCharFromUtf8(fileName);
            this.$set(res.data, "attNamq", fileName);
          }
          if (this.diaLogType == "waitDetail") {
            if (res.data) {
              this.waitDetail = res.data;
            }
          } else {
            this.editDetail = res.data;
            if (!res.data.backlogTaskAppointRequest) {
              this.$set(this.editDetail, "backlogTaskAppointRequest", {
                refId: "",
                backlogDate: "",
                attPath: "",
                attNamq: "",
                remark: ""
              });
            } else {
              if (!res.data.backlogTaskAppointRequest.attNamq && res.data.backlogTaskAppointRequest.attPath) {
                let lineIndex = res.data.backlogTaskAppointRequest.attPath.lastIndexOf("/");
                let fileName = res.data.backlogTaskAppointRequest.attPath.substring(
                  lineIndex + 1,
                  res.data.backlogTaskAppointRequest.attPath.length
                );
                fileName = this.getCharFromUtf8(fileName);
                this.$set(res.data.backlogTaskAppointRequest, "attNamq", fileName);
              }
            }
          }
        }
      });
    },
    //代办历史列表
    getHistoryTask(id) {
      historyTask({
        backlogTaskId: id
      }).then(res => {
        if (res.code == 0) {
          this.diaLogType = "historyDetail";
          this.diaLogTitle = "待办记录";
          this.historyDetail = res.data;
        }
      });
    },
    //代办详情下载
    uploadDown(file) {
      window.open(file);
    },
    submitFn(type) {
      console.log(type);
      this.$refs["waitEdit"].validate(valid => {
        if (valid) {
          let taskData = JSON.parse(JSON.stringify(this.editDetail));
          switch (type) {
            case "add":
              taskData.backlogDate = this.diaLogDate;
              if (!taskData.backlogTaskAppointRequest.refId) {
                taskData.backlogTaskAppointRequest = null;
              } else {
                taskData.backlogTaskAppointRequest.backlogDate = this.diaLogDate;
              }
              console.log("待办添加", taskData);
              addTask(taskData).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: "待办添加成功~",
                    type: "success"
                  });
                  this.diaLogVisible = false;
                  this.$parent.getTaskListData();
                  this.$parent.taskCalendar();
                }
              });
              break;
            case "edit":
              if (taskData.status == 1) {
                if (
                  taskData.backlogTaskAppointRequest.refId &&
                  !taskData.backlogTaskAppointRequest.backlogDate
                ) {
                  this.$message({
                    message: "请选择派送时间~",
                    type: "error"
                  });
                  return;
                }
                if (
                  taskData.backlogTaskAppointRequest.backlogDate &&
                  !taskData.backlogTaskAppointRequest.refId
                ) {
                  this.$message({
                    message: "请选择派送老师~",
                    type: "error"
                  });
                  return;
                }
                if (
                  !taskData.backlogTaskAppointRequest.refId ||
                  !taskData.backlogTaskAppointRequest.backlogDate
                ) {
                  taskData.backlogTaskAppointRequest = null;
                }
              } else {
                taskData.backlogTaskAppointRequest = null;
              }
              console.log("待办编辑", taskData);
              editTask(taskData).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: "待办编辑成功~",
                    type: "success"
                  });
                  this.diaLogVisible = false;
                  this.$parent.taskCalendar();
                  this.$parent.getTaskListData();
                }
              });
              break;
          }
        }
      });
    },
    dateSelect(value) {
      console.log("birthdaySelect", value);
      this.editDetail.backlogTaskAppointRequest.backlogDate = commonFn.timeFormat(
        value,
        "yyyy-MM-dd"
      );
    },
    successUploadMy(data) {
      console.log("successUploadMy", data);
      this.editDetail.attNamq = data.fileName;
      this.editDetail.attPath = data.fileData;
    },
    successUploadOther(data) {
      this.editDetail.backlogTaskAppointRequest.attNamq = data.fileName;
      this.editDetail.backlogTaskAppointRequest.attPath = data.fileData;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    statusRadio(prop) {
      console.log("checkRadio", prop);
      this.editDetail.status = prop.label;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/index.scss";

/deep/.el-dialog__body {
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

.flex_item {
  display: flex;
  align-items: center;
}

.bottom_line {
  height: 1px;
  background: #eeeeee;
  margin-top: 22px;
}

.down_load_box {
  display: flex;
  flex-direction: column;

  .down_load_btn {
    width: 128px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
    }
  }

  .down_load_tip {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
    margin-top: 5px;
  }
}

.submit_btn {
  display: flex;
  align-items: center;
  margin-top: 36px;

  .submit_btn_delete {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 16px;
      // color: #999999;
      color: $theme_color;
    }
  }

  .submit_btn_cancel {
    margin-left: auto;
    width: 76px;
    line-height: 34px;
    background: #ffffff;
    border: 1px solid $theme_color;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: $theme_color;
    text-align: center;
    cursor: pointer;
    margin-right: 16px;
  }

  .submit_btn_confirm {
    width: 76px;
    line-height: 34px;
    background: $theme_color;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }
}

.form_tag_green {
  width: 78px;
  line-height: 20px;
  background: rgba(91, 168, 151, 0.1);
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: $theme_color;
  text-align: center;
  display: inline-block;
}

.handle_form {
  .handle_form_row {
    margin-top: 25px;
    display: flex;
    // padding: 0 23px;

    .handle_form_item {
      flex: 1;
      display: flex;

      .handle_form_value {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
      }

      .handle_form_history {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: $theme_color;
        margin-left: auto;
        cursor: pointer;
      }

      .handle_form_ipt {
        width: 423px;
        border: 1px solid #eeeeee;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .handle_form_select {
        width: 423px;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        margin-top: 9px;
      }

      .handle_form_textarea {
        width: 423px;
        height: 120px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        outline: none;
        resize: none;
        padding: 6px 10px;
        box-sizing: border-box;
      }

      .handle_form_date {
        width: 423px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        position: relative;
        box-sizing: border-box;

        .form_date_icon {
          margin-right: 10px;
        }
      }

      .handle_form_date .el-date-editor {
        width: 100%;
        position: absolute; //绝对定位
        top: 0;
        left: 10px;
        opacity: 0; //设置完全透明
      }
    }
  }
}
</style>
