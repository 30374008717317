<template>
	<div class="inform_dialog">
		<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" :width="diaLogSize" :title="diaLogTitle" :visible.sync="diaLogVisible">
			<!-- 通知详情 -->
			<div class="inform_detail">
				<div class="inform_detail_title">
					{{inforData.title}}
				</div>
				<div class="inform_detail_content">
					{{inforData.detail}}
				</div>
				<div class="inform_detail_bottom" >
					<div>
						<div @click="downLoadFile" class="task_load_btn" v-if="inforData.params&&inforData.params.attach">
							<img src="@/assets/images/dashboard/downLoad.png">
							<span>附件下载</span>
						</div>
					</div>
					<div class="inform_detail_time">
						<span style="margin-right: 10px;">通知时间:</span>
						<span>{{inforData.createTime}}</span>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Bus from "./eventBus.js";
	export default {
		props: {
			isOpen: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			diaLogSize: {
				type: String,
				default: function() {
					return "579px"
				}
			},
			diaLogTitle: {
				type: String,
				default: function() {
					return ""
				}
			},
			detailId: {
				type: String,
				default: function() {
					return ""
				}
			},
		},
		watch: {
			isOpen: {
				handler(val) {
					this.diaLogVisible = val
				},
				deep: true
			}
		},
		data() {
			return {
				diaLogVisible: false,
				inforData: {}
			}
		},
		mounted() {
			console.log(this.inforData)
		},
		methods: {
			closeDialog() {
				this.$emit('closeDialog')
			},
			downLoadFile() {
				if (this.inforData.params && this.inforData.params.attach) {
					window.open(this.inforData.params.attach)
				} else {
					this.$message({
						message: "暂无可下载附件~",
						type: 'error'
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	/deep/.el-dialog {
		background: #FFFFFF;
		border-radius: 10px;
		padding: 19px 24px 24px 24px;
	}

	/deep/.el-form-item {
		margin: 0;
		display: flex;
	}

	/deep/.el-form-item__label {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #333333;
		margin-right: 10px;
		padding: 0;
	}

	/deep/.el-form-item__content {
		flex: 1;
		line-height: 20px;
		margin: 0 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.bottom_line {
		height: 1px;
		background: #EEEEEE;
		margin-top: 22px;
	}

	.task_load_btn {
		width: 128px;
		height: 34px;
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		img {
			width: 14px;
			height: 14px;
			margin-right: 10px;
		}

		span {
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 20px;
			color: #000000;
		}
	}

	.inform_detail {
		.inform_detail_title {
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			line-height: 24px;
			color: #000000;
			margin: 20px 0 10px 0;
			text-align: center;
		}

		.inform_detail_content {
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 24px;
			color: #666666;
			text-indent: 20px;
			margin-bottom: 56px;
		}

		.inform_detail_bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.inform_detail_time {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}
		}
	}
</style>
