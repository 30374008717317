<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-02-25 17:32:52
-->
<template>
  <div class="main_box">
    <transition name="el-fade-in-linear" mode="out-in">
      <!-- <keep-alive>
        <router-view></router-view>
      </keep-alive> -->
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
.main_box {
}
</style>
