<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-07-15 11:30:51
-->
<template>
	<div class="header_box">
		<!-- LOGO -->
		<div @click="toDashboard" class="header_logo">
			<img src="@/assets/images/dashboard/logo.png" alt="">
		</div>
		<!-- 导航栏 -->
		<div class="header_router">
			<router-link class="header_router_item" :class="{'header_router_active':item.name == activeRouter}"
				v-for="(item, index) in routeMenus" :key="index" tag="div" :to="item.path">{{ item.meta.title }}
			</router-link>
		</div>
		<!-- 个人中心 -->
		<div class="header_personal">
			<el-badge :value="personalData.badgeNum" :max="99" class="header_personal_badge">
				<img @click="showInformFn" class="header_personal_warn" src="@/assets/images/dashboard/warn.png" alt="">
				<div class="header_personal_inform">
					<div style="width: 400px;" class="personal_inform_inner" :class="{'inform_active': showInform}">
						<div class="personal_inform_title">
							通知列表
						</div>
						<div v-if="informList.length > 0" class="personal_inform_list">
							<div v-for="(item,index) in informList" :key="index" class="personal_inform_item">
								<div style="position: relative;margin-right: 15px;" class="">
									<img v-if="item.relatedObjType == 'SCHOOLSELECTION'"
										src="@/assets/images/header/inform2.png" class="inform_item_icon">
									<img v-else src="@/assets/images/header/inform1.png" class="inform_item_icon">
									<span v-if="!item.readStatus" class="inform_item_badge"></span>
								</div>

								<div class="inform_item_content">
									<el-tooltip v-if="item.relatedObjType != 'ANNOUNCE'" popper-class="tooltip_bg"
										class="item" effect="dark" placement="top-start">
										<div class="inform_item_left">
											<span>{{item.title}}</span>
											<span style="color: #333333;">{{item.detail}}</span>
										</div>
										<div slot="content" class="inform_tooltip">
											<span>{{item.title}}</span>
											<span>{{item.detail}}</span>
										</div>
									</el-tooltip>
									<el-tooltip v-else popper-class="tooltip_bg" class="item" effect="dark"
										placement="top-start">
										<div class="inform_item_left">
											<span>系统给你发送了一条通知</span>
											<span style="color: #333333;">通知标题：{{item.detail}}</span>
										</div>
										<div slot="content" class="inform_tooltip">
											<span>系统给你发送了一条通知</span>
											<span>通知标题：{{item.detail}}</span>
										</div>
									</el-tooltip>
									<div class="inform_item_right">
										<span>{{timeFormat(item.createTime)}}</span>
										<span
											@click="informDetail(item)">{{(item.relatedObjType == 'BACKLOGTASK' || item.relatedObjType == 'ANNOUNCE' || item.relatedObjType == 'TASK') ?'查看详情':'进入'}}</span>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="personal_inform_empty">
							<img src="@/assets/images/common/empty1.png">
							<span>暂无通知</span>
						</div>
						<div class="message_page">
							<el-pagination @current-change="currentChange" :current-page.sync="pageIndex"
								:page-size="pageSize" layout="total, prev, pager, next" small :total="totalCount">
							</el-pagination>
						</div>
					</div>
				</div>
			</el-badge>
			<el-dropdown trigger="click">
				<div class="flex_item">
					<img v-if="personalData.avator" class="header_personal_avator" :src="personalData.avator" alt="">
					<img v-else class="header_personal_avator" src="@/assets/images/common/avatar.png" alt="">
					<span class="header_personal_account">{{personalData.account}}</span>
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>
						<div @click="loginOut">退出登录</div>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 通知详情 -->
		<inform-dia data-dialog="personalDia" :detailId="relatedObjId" ref="informDia" @closeDialog="closeDialog"
			:isOpen="diaLogVisible" :diaLogTitle="diaLogTitle" />
		<!-- 任务详情 -->
		<task-form @clearBadgeNum="clearBadgeNum" ref="taskForm" :studentId="relatedObjId" :badgeId="badgeId" />
		<handle-form ref="handlerForm" @closeDialog="closeDialogH" :isOpen="diaLogVisibleH" :diaLogTitle="diaLogTitleH"
			:diaLogType="diaLogType" />
	</div>
</template>
<script>
	import * as asyncRouters from "@/router/asyncRouter";
	import informDia from "./modules/informDia.vue";
	import taskForm from "../../../views/taskProgress/components/taskForm.vue"
	import handleForm from "../../../views/dashboard/components/handleForm.vue"
	import storage from 'store';
	import store from '../../../store/index.js'
	import {
		getInformList
	} from "@/api/login";
	import commonFn from "@/utils/common.js";
	import {
		mapActions
	} from 'vuex';
	export default {
		components: {
			informDia,
			taskForm,
			handleForm
		},
		data() {
			return {
				pageIndex: 1,
				pageSize: 5,
				totalCount: 0,
				routeMenus: [],
				activeRouter: null,
				personalData: {
					badgeNum: 0,
					account: storage.get("ACCESS-NAME"),
					avator: storage.get("ACCESS-AVATAR"),
				},
				showInform: false,
				informList: [
					// {
					// 	title: "李飞给你派送了待办任务",
					// 	createTime: "今天  19:02",
					// 	content: "待办内容内容"
					// },
					// {
					// 	title: "李飞给你派送了待办任务",
					// 	createTime: "今天  19:02",
					// 	content: "待办内容内容待办内容内容待办内容内容待办内容内容待办内容内容待办内容内容"
					// },
				],
				diaLogVisible: false,
				diaLogTitle: "",
				relatedObjId: "",
				badgeId: "",
				diaLogVisibleH: false,
				diaLogTitleH: "",
				diaLogType: "",
			};
		},
		watch: {
			$route: {
				handler(to, from) {
					// console.log("$route", to);
					if (to.meta.parentRoute && to.meta.hidden) {
						this.activeRouter = to.meta.parentRoute;
					} else {
						this.activeRouter = to.name;
					}
					if (storage.get("ACCESS_BADGE")) {
						this.personalData.badgeNum = storage.get("ACCESS_BADGE")
						this.$forceUpdate()
					}
				},
				immediate: true
			},
		},
		created() {
			this.routeMenus = asyncRouters.default[0].children.filter(item => {
				return !item.meta.hidden
			});
			// setInterval(this.getBadgeNumFn, 2000)
			// console.log("routeMenus", storage.get("ACCESS_BADGE"));
		},
		mounted() {
			document.addEventListener("click", e => {
				// let clickWarn = e.target.dataset.dialog
				let clickForm = ""
				if (e.target.offsetParent && e.target.offsetParent["_prevClass"]) {
					clickForm = e.target.offsetParent["_prevClass"]
				}
				console.log("addEventListener", clickForm)
				if (clickForm == "el-badge header_personal_badge") {
					if (!this.showInform) {
						this.showInform = true;
						this.pageIndex = 1
						this.getInformListFn()
					} else {
						this.showInform = false;
					}
				} else if (clickForm == "header_personal_inform") {
					this.showInform = true;
				} else {
					this.showInform = false;
				}
				if (clickForm.includes("el-dialog")) {
					if (this.showInform) {
						this.showInform = true;
					}
					return
				}
			})
		},
		methods: {
			...mapActions(['badgeNumClear']),
			timeFormat(val) {
				let date = new Date(val)
				let month = this.addZero(date.getMonth() + 1)
				let day = this.addZero(date.getDate())
				let hour = this.addZero(date.getHours())
				let minute = this.addZero(date.getMinutes())
				return month + '-' + day + ' ' + hour + ':' + minute
			},
			addZero(num) {
				if (num < 10)
					return "0" + num;
				return num;
			},
			clearBadgeNum() {
				this.getInformListFn()
			},
			// 获取通知列表
			getInformListFn() {
				const params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				getInformList(params).then((res) => {
					if (res.code == 0) {
						if (res.data && res.data.length > 0) {
							this.informList = res.data
							this.totalCount = res.page.totalCount * 1
						}
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val
				this.getInformListFn()
			},
			toDashboard() {
				this.$router.push({
					path: "/dashboard",
				});
			},
			showInformFn() {
				// this.showInform = !this.showInform
				// if (this.showInform) {
				// 	this.getInformListFn()
				// }
			},
			getBadgeNumFn() {
				// 获取通知消息未读数
				store.dispatch('getBadgeNum').then((res) => {
					// console.log("dispatch", res)
					this.personalData.badgeNum = res
					this.$forceUpdate()
				})
			},
			informDetail(item) {
				this.badgeNumClear(item.id, {
					id: item.id
				}).then((res) => {
					// console.log("res", res)
					if (res.code == 0) {
						this.getInformListFn()
						this.getBadgeNumFn()
					}
				})
				if (item.relatedObjType == 'BACKLOGTASK') {
					console.log("待办详情", item)
					this.diaLogType = "waitDetail"
					this.diaLogTitleH = "待办详情"
					this.$nextTick(() => {
						this.$refs.handlerForm.getTaskDetail(item.relatedObjId)
					})
					this.diaLogVisibleH = true
				} else if (item.relatedObjType == 'ANNOUNCE') {
					this.detailId = item.relatedObjId
					this.diaLogTitle = "通知详情"
					this.$nextTick(() => {
						this.$refs.informDia.inforData = item
					})
					this.diaLogVisible = true
				} else if (item.relatedObjType == 'PAPERWORKFLOW') {
					console.log("informDetail", item)
					let studentId = item.params.studentId + ''
					let paperworkId = item.params.paperworkId
					// this.showInform = false
					console.log("PAPERWORKFLOW", this.$route)
					storage.set('studentId', studentId)
					if (this.$route.name != "ApplicationProgress") {
						this.$router.push({
							path: `/applicationProgress?type=3&&studentId=${studentId}&&paperworkId=${paperworkId}`
						})
					} else {
						this.$router.push({
							query: {
								type: 3,
								studentId: studentId,
								paperworkId: paperworkId,
							}
						});
					}
				} else if (item.relatedObjType == 'SCHOOLSELECTION') {
					let studentId = item.params.studentId + ''
					storage.set('studentId', studentId)
					this.$router.push({
						path: `/applicationProgress?type=1&&studentId=${studentId}`
					})
				} else if (item.relatedObjType == 'NEWS') {
					this.$router.push({
						path: `/industryInformation`
					})
				} else if (item.relatedObjType == 'TASK') {
					// let studentId = item.senderId
					this.relatedObjId = item.relatedObjId
					this.badgeId = item.id
					this.$nextTick(() => {
						this.$refs.taskForm.taskType = "monthviewClassTaskEdit"
						this.$refs.taskForm.diaLogTitle = "任务详情"
						this.$refs.taskForm.viewsTaskDetail()
						this.$refs.taskForm.diaLogVisible = true
					})
					// this.$router.push({
					// 	path: `/taskProgress?type=2&&studentId=${studentId}`
					// })
				}
			},
			closeDialog() {
				this.diaLogVisible = false
			},
			closeDialogH() {
				this.diaLogVisibleH = false
			},
			loginOut() {
				console.log("loginOut")
				this.$store.dispatch("Logout").then(() => {
					setTimeout(() => {
						window.location.reload();
					}, 16);
				});
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.personal_inform_list::-webkit-scrollbar {
		display: none;
	}

	/deep/.el-dropdown-menu__item:hover {
		background-color: rgba(91, 168, 151, 0.2);
		color: $theme_color;
	}

	.inform_tooltip {
		display: flex;
		flex-direction: column;
	}

	.flex_item {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.header_box {
		height: 80px;
		padding: 0 24px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		box-shadow: 0px 3px 6px rgba(91, 168, 151, 0.16);
		border-radius: 0px 0px 10px 10px;

		.header_logo {
			display: flex;
			align-items: center;
			cursor: pointer;

			img {
				width: 200px;
				height: 37px;
				margin-right: 100px;
			}
		}

		.header_router {
			width: 1064px;
			display: flex;
			align-items: center;

			.header_router_item {
				width: 152px;
				// flex: 1;
				line-height: 80px;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
				cursor: pointer;
				text-align: center;
				user-select: none;
			}

			.header_router_active {
				background-color: $theme_color;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				line-height: 80px;
				border-radius: 0px 0px 10px 10px;
				color: #ffffff !important;
			}
		}

		.header_personal {
			display: flex;
			align-items: center;
			margin-left: auto;

			.header_personal_badge {
				height: 30px;
				position: relative;
			}

			.header_personal_warn {
				width: 30px;
				height: 30px;
				cursor: pointer;
			}

			.header_personal_inform {
				position: absolute;
				right: 0;
				top: 40px;
				z-index: 300;


				.personal_inform_inner {
					// width: 357px;
					height: 0;
					background: #FFFFFF;
					box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
					transition: all 0.2s linear;
					display: flex;
					flex-direction: column;
					overflow: hidden;


					.personal_inform_title {
						height: 40px;
						background: #F6FBFA;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #333333;
						padding: 10px 15px;
						box-sizing: border-box;
					}

					.personal_inform_list {
						flex: 1;
						display: flex;
						flex-direction: column;
						overflow: hidden;
						overflow-y: scroll;

						.personal_inform_item {
							padding: 20px 0;
							display: flex;
							align-items: center;
							margin: 0 18px;

							.inform_item_icon {
								width: 29px;
								height: 36px;
							}

							.inform_item_badge {
								width: 6px;
								height: 6px;
								background: #D03B3B;
								border-radius: 50%;
								position: absolute;
								top: 0;
							}

							.inform_item_content {
								flex: 1;
								display: flex;
								align-items: center;
								justify-content: space-between;

								.inform_item_left {
									flex: 1;
									display: flex;
									flex-direction: column;
									font-size: 12px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 17px;
									color: #999999;
									margin-bottom: 2px;
									cursor: pointer;

									span {
										width: 210px;
										overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;
									}
								}

								.inform_item_right {
									width: 100%;
									display: flex;
									flex-direction: column;
									font-size: 12px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 17px;
									color: #333333;
									text-align: right;
								}

								.inform_item_right :nth-child(2) {
									color: $theme_color;
									cursor: pointer;
								}
							}
						}

					}

					.personal_inform_list :not(:last-child).personal_inform_item {
						border-bottom: 1px solid #F0F0F0;
					}

					.message_page {
						height: 52px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.personal_inform_empty {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						img {
							width: 200px;
							height: 150px;
						}

						span {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 20px;
							color: #999999;
						}
					}

					&.inform_active {
						height: 471px;
					}
				}
			}

			.header_personal_avator {
				width: 30px;
				height: 30px;
				cursor: pointer;
				margin: 0 10px 0 20px;
				border-radius: 50%;
			}

			.header_personal_account {
				width: 80px;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #303133;
				cursor: pointer;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
</style>
