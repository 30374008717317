<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-09 18:23:56
 * @LastEditTime: 2021-06-09 18:40:47
-->
<template>
  <el-breadcrumb separator="/">
    <el-breadcrumb-item v-for="(item, index) in breadcrumbArr" :key="index" :to="{ path: index === 0 ? '/' : '' }">{{item.meta.title}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  props: {
    breadcrumbArr: {
      type: Array,
      default: undefined,
    },
  },
  created() {
    console.log('9996', this.breadcrumbArr)
  },
};
</script>
<style lang="" scoped>
</style>
