<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:03:02
 * @LastEditTime: 2021-06-29 16:20:08
-->
<template>
  <el-menu
    mode="vertical"
    class="sidebar-el-menu"
    :default-active="onRoutes"
    :collapse="collapse"
    background-color="#324157"
    text-color="#bfcbd9"
    active-text-color="#20a0ff"
    unique-opened
    router
  >
    <template v-for="itemF in menus">
      <!-- 有二级菜单 -->
      <template v-if="itemF.children && itemF.children.length > 0">
        <!-- 一级菜单 -->
        <el-submenu :index="itemF.path" :key="itemF.path">
          <!--  一级标题 -->
          <template #title>
            <!-- <i :class="itemF.icon"></i> -->
            <span>{{ itemF.meta.title }}</span>
          </template>
          <!-- 二级菜单 -->
          <template v-for="itemS in itemF.children">
            <!-- 有三级菜单 -->
            <el-submenu
              v-if="itemS.children"
              :index="itemS.path"
              :key="itemS.path"
            >
              <!-- 二级标题 -->
              <template #title>{{ itemS.meta.title }}</template>
              <!-- 三级菜单 -->
              <el-menu-item
                v-for="itemT in itemS.children"
                :key="itemT.path"
                :index="itemT.path"
                >{{ itemT.meta.title }}</el-menu-item
              >
            </el-submenu>
            <!-- 二级菜单 -->
            <el-menu-item v-else :index="itemS.path" :key="itemS.path">{{
              itemS.meta.title
            }}</el-menu-item>
          </template>
        </el-submenu>
      </template>
      <!-- 只有一级菜单 -->
      <template v-else>
        <el-menu-item :index="itemF.path" :key="itemF.path">
          <i :class="itemF.meta.icon"></i>
          <template #title>{{ itemF.meta.title }}</template>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>
<script>
import * as asyncRouters from "@/router/asyncRouter";
export default {
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menus: asyncRouters.default[0].children
    };
  },
  methods: {
  },
  computed: {
    onRoutes() {
      console.log("路由", this.menus);
      this.$emit('changeBread', this.$route)
      return this.$route.path;
    },
    // collapse() {
    //   return this.$store.state.collapse;
    // },
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
</style>
