<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-02-25 17:12:11
-->
<template>
  <div class="layout">
    <el-container>
      <!-- 顶部导航栏 -->
      <el-header>
        <Header />
      </el-header>
      <!-- 内容区域 -->
      <el-main>
        <!-- <Breadcrumb :breadcrumbArr="breadcrumbArr" /> -->
        <Main :breadcrumbArr="breadcrumbArr" />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Header from "./components/headers";
import Slider from "./components/slider";
import Main from "./components/main";
import Breadcrumb from "./components/breadcrumb";
import { mapState } from "vuex";
export default {
  name: "LayOut",
  components: {
    Header,
    Slider,
    Main,
    Breadcrumb
  },
  data() {
    return {
      breadcrumbArr: []
    };
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    })
  },
  methods: {
    changeBread(props) {
      this.breadcrumbArr = props.matched;
    }
  },
  mounted() {
    // console.log("当前breadcrumbArr是>>>>", this.breadcrumbArr);
    // console.log("mainMenus", this.mainMenu);
  }
};
</script>
<style lang="scss" scoped>
</style>
